import React, { Component } from 'react';
import { Form, Button, Row, Col, Container } from 'react-bootstrap';


const myCol = (props) => {
    return (
        <input type={props.type} name={props.name} onChange={props.handleChange} className="form-control" defaultValue={props.defaultValue}  />
    )
}

class SharesForm extends Component{

    sharesInfo = {};


    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value});
    }

    saveAndContinue = (e) => {
        e.preventDefault();
        let obj = {};
        this.props.companyList.map( (company) => {
            //(
                if (this.state && this.state[`shares${company.id}`] > 0) {
                    obj = {...obj, ...{[`shares${company.id}`] : this.state[`shares${company.id}`]}}
                }
            //)

        });

        console.log("Obj", obj);

        // if (Object.keys(obj).length > 0) {
        //     console.log("Setting sharesinfo");
        //     this.sharesInfo = obj;
        // }
        // console.log("sharesInfo", this.sharesInfo);

        if (Object.keys(obj).length > 0) {
            this.props.storeInfo(obj);
            Object.keys(obj).forEach(key => {
                this.setState({[key]: ""});
            })
        }

        // this.props.nextStep();

    };

    createCompanyList = (companyList) => {
        const list = companyList.map((company) =>
            <div key={company.id}>
                <Row>
                    {/*<Col md={1}></Col>*/}
                    <Col md={12} className="text-start">
                        <Form.Group as={Col}>
                            {/* <Form.Label className="label">Company #111</Form.Label> */}
                            {company.name}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    {/*<Col md={1}></Col>*/}
                    <Col md={12} className="text-start">
                        <em>
                            {company.description}
                        </em>

                    </Col>

                </Row>
                <Row className="text-center">
                    <Col> </Col>
                    <Col className="">
                        <Form.Group as={Col}>
                            {company.outstanding_shares}
                        </Form.Group>
                    </Col>
                    <Col className="">
                        <Form.Group as={Col}>
                            {company.shares}
                        </Form.Group>
                    </Col>
                    <Col className="">
                        <Form.Group as={Col}>
                            { ((company.shares/company.outstanding_shares)*100).toFixed(2) }

                        </Form.Group>
                    </Col>
                    <Form.Group as={Col}>
                        <Form.Control
                            name={"shares" + company.id}
                            type="number"
                            value={this.state ? this.state[`shares${company.id}`] : ""}
                            onChange={this.handleChange}
                        />
                    </Form.Group>
                    <br/><br/>
                </Row>
            </div>
        );
        return (
            <div>
                {list}
            </div>
        );
    }


    render() {
        const theList = this.createCompanyList(this.props.companyList);
        return( <Container>
                <Form>
                    <Row>
                        <Col>
                            <h5>Our Mission</h5>
                            The <cite>shareholdersvoice</cite> mission is to provide a platform to give voice to the silent
                            shareholders who may have become concerned about the policy and the direction of the company
                            in which they have invested their money. <br/>
                            As a shareholder of a public company, it is important for you to let the company know what
                            you think about the major direction the company is taking, such as vaccine mandates,
                            implementing CRT in the workplace, anti-American politics, or any other non-business-related
                            causes.
                            <h5 className="mt-3">Why?</h5>
                            Some of these over-zealous initiatives and actions may end up being
                            <cite> illegal</cite>, create unwanted labor tension, cause unnecessary loss to the company, and
                            distract the company from its core business objectives. <br/>
                            It’s time for the shareholders to speak up to protect their investment and hold the company
                            executives <em>accountable</em>.
                            <h5 className="mt-3">The Problem</h5>
                            In recent years, we've seen startling influence by the political left in our large corporations.
                            They achieve this mostly in the form of shareholder leverage. In short, all it takes is a shareholder
                            position of 1% of outstanding shares to submit proposed additions/changes to corporate policy to
                            be voted upon by all shareholders. <br/>
                            <h5 className="mt-3">A Possible Solution</h5>
                            We propose to apply the same strategy.  Like the left, by pooling our collective shareholder
                            power in an effort to achieve 1% or more interest in specific companies where their <em>direction </em>
                            is compromised, we have a chance to use those shares to put forth proposals to those
                            companies, which the company MUST present to all the shareholders in their annual meeting
                            to have those proposals voted upon. <br/><br/>
                            Vote with your shares! Let the company which you are a part owner of know what you think
                            about the direction the company is going and what direction you want them to go instead.
                            The silent shareholders are NO LONGER silent! <br/><br/><br/>

                            Below, we are collecting information on shareholder positions in an effort to determine
                            where we stand. The companies listed are those that we have identified as making decisions
                            contrary to Constitutional rights and basic freedoms. <br/>
                            We ask your support by also supplying your email address so that we may connect with you in this
                            cause. <br/><br/>

                            The information will be kept confidential.
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Row className="text-center">
                                <Col className="fw-bold">
                                    <Form.Group as={Col}>
                                        <Form.Label className="label fw-bold">Company</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className="fw-bold">
                                    <Form.Group as={Col}>
                                        <Form.Label className="label fw-bold">Outstanding Shares</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className="fw-bold">
                                    <Form.Group as={Col} className="text-center">
                                        <Form.Label className="label fw-bold">Share Tally</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className="fw-bold">
                                    <Form.Group as={Col} className="text-center">
                                        <Form.Label className="label fw-bold">Percent</Form.Label>
                                    </Form.Group>
                                </Col>
                                <Col className="fw-bold">
                                    <Form.Group as={Col}>
                                        <Form.Label className="label fw-bold">Shares You Own</Form.Label>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {theList}

                        </Col>

                        <Col sm={0} lg={5}>
                        </Col>

                        <Row  className="mt-5 text-center">
                            <Col xs={12} md={6}>
                                <Row className="mt-5">
                                    <Form.Group as={Col}  controlId="formFirstName">
                                        <Form.Label className="label">First Name (<cite>optional)</cite></Form.Label>
                                        <Form.Control
                                            // style={{width: 350}}
                                            type="text"
                                            defaultValue={this.props.inputValues.firstName}
                                            name="firstName"
                                            required
                                            onChange={this.props.handleChange}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} controlId="formLastName">
                                        <Form.Label className="label">Last Name (<cite>optional)</cite></Form.Label>
                                        <Form.Control
                                            // style={{width: 350}}
                                            type="text"
                                            defaultValue={this.props.inputValues.lastName}
                                            name="lastName"
                                            required
                                            onChange={this.props.handleChange}
                                        />
                                    </Form.Group>
                                </Row>
                            </Col>
                            {/*<xCol className="col-4 border">*/}
                            {/*    test*/}
                            {/*</xCol>*/}

                        </Row>
                        <Row>
                            <Col xs={12} md={6} >
                                <Form.Group controlId="formEmail">
                                    <Form.Label className="label">Email Address (<cite>optional, but strongly requested)</cite></Form.Label>
                                    <Form.Control
                                        type="email"
                                        defaultValue={this.props.inputValues.email}
                                        name="email"
                                        required
                                        onChange={this.props.handleChange}
                                    />
                                </Form.Group>

                            </Col>

                        </Row>



                    </Row>
                    <Button className="mt-5"  variant="primary" onClick={this.saveAndContinue}>Submit</Button>

                    </Form>
                </Container>
        );
    }
}

export default SharesForm;
