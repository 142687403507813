import './App.css';
import React, {Component} from "react";
import SharesForm from "./SharesForm";
import axios from 'axios';

class App extends Component {
  state = {
    step: 1,
    firstName: '',
    lastName: '',
    email: '',
    companyList: [],
  }


  loadData = async () => {

    // Support local debugging.  i.e. 443 used for production, 80 used for local
    let port = 443;
    if (window.location.hostname === "localhost") {
      port = 80;
    }
    axios.defaults.baseURL = window.location.protocol + "//" + window.location.hostname + ":" + port;

    let response = await axios.get( '/getcompanies.php');
    // let data = await response.json();
    this.setState({companyList: response.data} );
  }

  componentDidMount() {
    this.loadData();
    setInterval( () => {
      this.loadData();
    }, 10000)
  }

  nextStep = () => {
    this.setState((preState) => ({
      step: preState.step + 1
    }))
  }

  prevStep = () => {
    this.setState((preState) => ({
      step : preState.step - 1
    }))
  }

  storeInfo = (sharesInfo) => {
    const body = {
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      shares: sharesInfo
    };

    let response = axios.post("/setshares.php",
        body,
        {headers: {'Content-Type': 'application/json'}}
    )
        .catch(err => console.log(err))
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    const { step, firstName, lastName, email } = this.state;
    const inputValues = { firstName, lastName, email };
    switch(step) {
      case 1:
        return <SharesForm
            companyList={this.state.companyList}
            storeInfo={this.storeInfo}
            handleChange = {this.handleChange}
            inputValues={inputValues}
        />
      // Leave room for a thank you page
    }
  }
}

export default App;
